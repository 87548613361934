@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.medicina-font {
    font-family: 'Bebas Neue';
}

.germinando {
    height: 512px;
    width: 290px;
    background: url(./assets/germinando.png);
    animation-name: sprite;
    animation-duration: 1.5s;
    animation-timing-function: steps(9);
    animation-iteration-count: infinite;
}

.carrito-bloom {
    animation-name: sprite2;
    animation-duration: 1s;
    animation-timing-function: steps(14);
    animation-iteration-count: 1;
}

.carrito {
    height: 41px;
    width: 41px;
    background: url(./assets/carrito.png);
    background-position: 0px;
}

.slick-next {
    right: 60px !important;
    z-index: 10 !important;
}

.slick-prev {
    left: 20px !important;
    z-index: 10 !important;
}

.slick-prev:before,
.slick-next:before {
    color: #42050a !important;
    font-size: 60px !important;
    margin: 0px !important;
}

.slick-dots li button:before {
    font-size: 18px !important;
    color: #42050a !important;
}

.slick-list {
    z-index: 1 !important;
}

.slick-slide {
    z-index: 1 !important;
    position: relative !important;
}

body,
html,
#root {
    width: 100%;
    height: 100%;
}

@keyframes sprite {
    from {
        background-position: 0px;
    }
    to {
        background-position: -2610px;
    }
}

@keyframes sprite2 {
    from {
        background-position: 0px;
    }
    to {
        background-position: -574px;
    }
}